<template>
    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

        <feather-icon icon="BellIcon" class="cursor-pointer mt-1 sm:mr-3 mr-2" :badge="notifications.length"></feather-icon>

        <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">

            <VuePerfectScrollbar ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0" :class="{'mb-10':(notifications.length > 0)}" :settings="settings">

                <ul class="bordered-items" v-if="notifications.length > 0">
                    <li v-for="ntf in notifications" :key="ntf.id" class="flex justify-between px-4 py-4 notification cursor-pointer" @click="onClickNotification(ntf)">
                        <div class="flex items-start">

                            <feather-icon :icon="iconMapping[ntf.data.type]" :svg-classes="iconClasses[ntf.data.type]"></feather-icon>

                            <div class="mx-2">
                                <span class="font-bold block notification-title">{{ ntf.data.title }}</span>
                                <small>{{ ntf.data.description }}</small>
                            </div>

                        </div>

                        <small class="mt-1 whitespace-no-wrap">{{ elapsedTime(ntf.created_at) }}</small>
                    </li>
                </ul>

                <ul class="bordered-items" v-else>
                    <li class="px-4 py-4" @click="onClickNotification(ntf)">
                        <span class="block text-center">Keine neuen Benachrichtigungen</span>
                    </li>
                </ul>

            </VuePerfectScrollbar>

            <div v-if="notifications.length > 0" @click="markAllNotifications" class="checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer">
                <span>Alle als gelesen markieren</span>
            </div>

        </vs-dropdown-menu>

    </vs-dropdown>

</template>

<script>
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: "NotificationDropdown",
        components: {VuePerfectScrollbar},
        data() {
            return {
                settings: {
                    maxScrollbarLength: 60,
                    wheelSpeed: .60,
                },
                iconMapping: {
                    calendar: 'CalendarIcon',
                    commission: 'BookOpenIcon',
                    recall: 'PhoneForwardedIcon',
                },
                iconClasses: {
                    calendar: ['text-success', 'stroke-current mr-1 h-6 w-6'],
                    commission: ['text-success', 'stroke-current mr-1 h-6 w-6'],
                    recall: ['text-warning', 'stroke-current mr-1 h-6 w-6']
                }
            }
        },

        created() {
            this.updateNotifications();

            setInterval(() => {
                this.updateNotifications();
            }, 12000);
        },

        computed: {
            ...mapGetters([
                'notifications'
            ]),
        },

        methods: {
            ...mapActions([
                'updateNotifications',
                'markNotification',
                'markAllNotifications'
            ]),

            onClickNotification(ntf) {
                this.markNotification(ntf.id);
                this.$router.push(ntf.data.path);
            },

            elapsedTime(startTime) {
                let x = new Date(startTime);
                let now = new Date();
                var timeDiff = now - x;
                timeDiff /= 1000;

                var seconds = Math.round(timeDiff);
                timeDiff = Math.floor(timeDiff / 60);

                var minutes = Math.round(timeDiff % 60);
                timeDiff = Math.floor(timeDiff / 60);

                var hours = Math.round(timeDiff % 24);
                timeDiff = Math.floor(timeDiff / 24);

                var days = Math.round(timeDiff % 365);
                timeDiff = Math.floor(timeDiff / 365);

                var years = timeDiff;

                if (years > 0) {
                    return 'vor ' + years + (years > 1 ? ' Jahre' : ' Jahr');
                } else if (days > 0) {
                    return 'vor ' + days + (days > 1 ? ' Tage ' : ' Tag ');
                } else if (hours > 0) {
                    return 'vor ' + hours + ' Std.';
                } else if (minutes > 0) {
                    return 'vor ' + minutes + ' Min.';
                } else if (seconds > 30) {
                    return 'vor ' + seconds + (seconds > 1 ? ' Sekunden' : ' Sekunde');
                }

                return 'Gerade eben';
            }
        },
    }
</script>

<style scoped>

</style>