<template>
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

    <feather-icon icon="ClockIcon"
                  class="cursor-pointer mt-1 sm:mr-6 mr-2"
                  :class="{'text-success': !isPaused,'text-danger': isPaused}">
    </feather-icon>

    <vs-dropdown-menu class="stamp-terminal-dropdown dropdown-custom vx-navbar-dropdown">

      <div id="stamp-terminal-loading" class="vs-con-loading__container">

        <template v-if="timeSheet">

          <div class="stamp-terminal-timer">
            <div class="stamp-terminal-timer-counter" :class="{'is-paused ': isPaused, 'text-success': !isPaused}">
              {{ currentMinutes | hours }} <span class="font-normal">Std.</span>
            </div>

            <div class="stamp-terminal-timer-action" @click="createTimeSheetEntry">

            <span class="timer-action action-play" v-if="isPaused">
              <feather-icon icon="PlayIcon" class="cursor-pointer"></feather-icon>
            </span>

              <span class="timer-action action-pause" v-else>
              <feather-icon icon="PauseIcon" class="cursor-pointer"></feather-icon>
            </span>

            </div>
          </div>

          <div class="stamp-terminal-history">
            <template v-if="getDateForToday.entries">
              <template v-for="(entry, index) in getDateForToday.entries">
                <span>{{ entry.timestamp | time }}</span>
                <span v-if="(index + 1) < getDateForToday.entries.length"> - </span>
              </template>
            </template>
          </div>

        </template>

        <template v-else>
          <div class="p-4 text-center"> Kein Arbeitszeitbreicht verfügbar</div>
        </template>
      </div>

    </vs-dropdown-menu>

  </vs-dropdown>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import ApiService from "../../../api";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "StampTerminalDropdown",

  data() {
    return {
      timeSheet: null,
      currentMinutes: 0,
    }
  },

  computed: {
    ...mapGetters(['userId']),

    isPaused() {
      if (!this.getDateForToday) {
        return true;
      }

      const dateEntry = this.getDateForToday;

      if (!dateEntry.entries) {
        return true;
      }

      return (dateEntry.entries.length % 2) === 0;
    },

    getDateForToday() {
      if (!this.timeSheet) {
        return null;
      }

      const result = this.timeSheet.date_entries[moment().format('YYYY-MM-DD')];

      if (result) {
        return cloneDeep(result)
      }

      return null;
    },
  },

  created() {
    this.fetchData();
  },

  mounted() {
    setInterval(() => {
      this.calculateTimer();
    }, 15000);
  },

  methods: {
    fetchData() {
      const year = moment().format('YYYY');
      const month = moment().format('MM');

      ApiService.get(`time-sheet` +
          `?filter[user_id]=${this.userId}` +
          `&filter[year]=${year}` +
          `&filter[month]=${month}` +
          `&limit=1` +
          `&includes=entries` +
          `&appends=date_entries`
      ).then(response => {
        this.stopLoader();
        if (response.data.result) {
          this.timeSheet = response.data.result[0];
          this.calculateTimer();
        }
      });
    },

    createTimeSheetEntry() {
      this.openLoader();

      const data = {
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
        time_sheet_id: this.timeSheet.id
      }

      ApiService.post('time-sheet-entry', data).then(() => {
        this.fetchData();
      }).catch(error => {
        error.handleUnkown = true;
        error.handleGlobally();
      });
    },

    openLoader() {
      this.$vs.loading({
        container: '#stamp-terminal-loading',
        scale: 0.6
      })
    },

    stopLoader() {
      this.$vs.loading.close('#stamp-terminal-loading > .con-vs-loading')
    },

    calculateTimer() {
      const currentDate = cloneDeep(this.getDateForToday);

      if (!currentDate) {
        this.currentMinutes = 0;
        return;
      }

      const trackedMinutes = parseFloat(currentDate.hours) * 60;

      if (this.isPaused) {
        this.currentMinutes = trackedMinutes;
        return;
      }

      if(!this.getDateForToday.entries || this.getDateForToday.entries.length < 1) {
        this.currentMinutes = trackedMinutes;
        return;
      }

      const lastEntry = this.getDateForToday.entries[this.getDateForToday.entries.length - 1];
      const diff = moment().diff(moment(lastEntry.timestamp));
      const duration = moment.duration(diff).asMinutes();

      this.currentMinutes = trackedMinutes + duration;
    }
  },

  filters: {
    time(date) {
      return moment(date).format('HH:mm');
    },

    hours(time) {
      let hours = (time / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);

      rhours = ("0" + rhours).slice(-2);
      rminutes = ("0" + rminutes).slice(-2);

      return rhours + ":" + rminutes;
    }
  }

}
</script>

<style lang="scss">
</style>