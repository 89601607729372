<template>
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

    <feather-icon icon="UsersIcon"
                  class="cursor-pointer mt-1 sm:mr-6 mr-2">
    </feather-icon>

    <vs-dropdown-menu class="stam-dropdown dropdown-custom vx-navbar-dropdown">
      <div class="p-4" v-if="sessionAuthKeys.length >= 1">
        <b>Benutzer wechseln:</b><br>
        <div v-for="sessionAuthKey in sessionAuthKeys" class="d-block change-workspace-link" >
          <span class="link d-inline" @click="changeAuth(sessionAuthKey)">
             Workspace  "{{sessionAuthKey.unique_name}}"
          </span>
            <span class="link d-inline icon" @click="removeAuth(sessionAuthKey)">
               <vs-icon icon="close"></vs-icon>
            </span>
        </div>

      </div>

      <vs-alert color="#7795D1" :active="sessionAuthKeys.length === 0" icon="info" class="mb-2 mt-1 small-alert" >
      Keine verknüpften Workspaces
      </vs-alert>

      <div id="stamp-terminal-loading" class="vs-con-loading__container" @keyup.enter="login">
        <template>
          <div class="p-4">
            <b>Weiteren Zugang hinzufügen</b>
            <vs-input
                v-validate="'required|email'"
                name="field1"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                label-placeholder="Email"
                v-model="email"
                class="w-full"/>
            <span class="text-danger text-sm">{{ errors.first('email') }}</span>

            <vs-input
                v-validate="'required'"
                type="password"
                name="field2"
                icon-no-border
                icon="icon icon-lock"
                icon-pack="feather"
                label-placeholder="Passwort"
                v-model="password"
                class="w-full mt-6"/>
            <span class="text-danger text-sm">{{ errors.first('password') }}</span>
            <vs-button :disabled="!validateForm" @click="login" class="float-right">Login</vs-button>

          </div>
        </template>
      </div>

    </vs-dropdown-menu>

  </vs-dropdown>
</template>

<script>
import {mapGetters} from "vuex";
import ApiService from "@/api";


export default {
  name: "ChangeWorkspaceSessionDropdown",

  data() {
    return {
      email: "",
      password: "",
      sessionAuthKeys: [],
    }
  },

  computed: {
    ...mapGetters(['userId']),
    validateForm() {
      return !this.errors.any() && this.email != '' && this.password != '';
    },
  },

  created() {

  },

  mounted() {
    this.setSessionAuthKeys();
  },

  methods: {
    setSessionAuthKeys(){
      var results = [];
      for ( var info in window.localStorage ) {
        if (info.substring(0, 7) === "apiKey_") {

          let result =  {
            "apiKey": window.localStorage.getItem(info),
            "workspace_id": info.substring(7, 8),

          }

          ApiService.get('workspaces/' + info.substring(7, 8)).then((response) => {
            result.unique_name =  response.data.result.unique_name

            results.push(result)
          }).catch(response => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Error',
              text: "Fehler",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })

        }
      }
      this.sessionAuthKeys = results;
    },
    fetchData() {

    },
    login() {
      let me = this;

      if (!this.validateForm) return;

      me.$vs.loading();

      ApiService.post('auth', {email: this.email, password: this.password}).then((response) => {
        if (response.status !== 200) {
          return;
        }
        this.saveWorkspaceAuthToSession(response.data)
        localStorage.setItem("current_workspace", response.data.workspace.id);

        this.$router.go()
        me.$vs.loading.close();


      }).catch((error) => {
        me.$vs.loading.close();
        me.$vs.notify({
          title: 'Error',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });

    },
    changeAuth(sessionAuth){
      localStorage.current_workspace = sessionAuth.workspace_id;

      this.$router.go()
    },
    removeAuth(sessionAuth){

      localStorage.current_workspace = null;
      localStorage.removeItem("apiKey_"+sessionAuth.workspace_id)

      this.$router.go()
    },

    saveWorkspaceAuthToSession(data) {
      localStorage.setItem("apiKey_"+data.workspace.id, data.api_key);
    },

  },

  filters: {

  }

}
</script>

<style lang="scss">
  .change-workspace-link .icon {
    display: none;
    position: absolute;
  }
  .change-workspace-link:hover .icon {
    display: inline;
  }
</style>